<template>
	<v-container fluid style="max-width: 1600px">
		<v-chip-group v-model="chipSelected" column color="sidebarBackground" active-class="sidebarBackground" class="my-3">
			<v-chip value="NewsfeedPosts" :disabled="chipSelected == 'NewsfeedPosts'">
				<v-btn text :to="{ name: 'NewsfeedPosts' }" block rounded plain class="px-0">
					<v-icon class="mr-3"> mdi-newspaper </v-icon>
					{{ $t('newsfeed.posts') }}
				</v-btn>
			</v-chip>
			<v-chip value="NewsfeedUploads" :disabled="chipSelected == 'NewsfeedUploads'" v-if="setupInfo.accountType.student.value">
				<v-btn text :to="{ name: 'NewsfeedUploads' }" block rounded plain class="px-0">
					<v-icon class="mr-3"> mdi-cloud-upload-outline </v-icon>
					{{ $t('newsfeed.uploads') }}
				</v-btn>
			</v-chip>
		</v-chip-group>

		<router-view />
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('nav.newsfeed'),
		}
	},
	data() {
		return {
			chipSelected: 'NewsfeedPosts',
		}
	},
	computed: {
		...mapGetters({
			setupInfo: 'user/setupInfo',
		}),
	},
	created() {
		this.chipSelected = this.$route.name
	},
}
</script>

<style scoped>
.v-chip--disabled {
	opacity: 1;
}
</style>
